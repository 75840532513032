@import './scss/vars';
@import './scss/fonts';

* {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  -webkit-text-size-adjust: 100%;

  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body,
#root {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
