@import '../scss/vars';

.welcome {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 3rem;
  background-image: linear-gradient(
    180deg,
    $color-green-bright 0%,
    $color-green-dark 100%
  );
  font-family: $font-family-copy, sans-serif;
  font-weight: $font-weight-copy;
  color: $color-white;
}

.welcome__logo {
  display: block;
  width: 40vw;
  max-width: 300px;
}

.welcome__intro {
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.5rem;
  text-align: center;
}

.welcome__link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  font-family: $font-family-headlines, sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  background: $color-white;
  border-radius: 9999px;
  color: $color-green-dark;
  transition: transform 0.2s ease-in-out;
  transform-origin: 50% 50%;

  &:hover,
  &:focus-visible {
    transform: scale(1.05);
  }
}

.welcome__facetime {
  width: auto;
  height: 0.6em;
}

.welcome__footer {
  font-size: 1rem;
  text-align: center;
}

.welcome__beyond {
  display: inline-block;
  margin: 0 0.25em;
  transition: transform 0.2s ease-in-out;
  transform-origin: 50% 50%;

  &:hover,
  &:focus-visible {
    transform: scale(1.05);
  }

  > img {
    width: auto;
    height: 1.6em;
    margin-bottom: -0.4em;
  }
}
