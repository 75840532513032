@use 'sass:list';

$fonts: (
  ('Optician Sans', 'optician-sans', 400, 'normal'),
  // ('Ogg', 'ogg-regular', 400, 'normal'),
  // ('Ogg', 'ogg-regular-italic', 400, 'italic'),
  // ('Supreme', 'supreme-thin', 100, 'normal'),
  // ('Supreme', 'supreme-thin-italic', 100, 'italic'),
  // ('Supreme', 'supreme-extra-light', 200, 'normal'),
  // ('Supreme', 'supreme-extra-light-italic', 200, 'italic'),
  // ('Supreme', 'supreme-light', 300, 'normal'),
  // ('Supreme', 'supreme-light-italic', 300, 'italic'),
  // ('Supreme', 'supreme-regular', 400, 'normal'),
  // ('Supreme', 'supreme-italic', 400, 'italic'),
  ('Supreme', 'supreme-medium', 500, 'normal'),
  // ('Supreme', 'supreme-medium-italic', 500, 'italic'),
  // ('Supreme', 'supreme-bold', 700, 'normal'),
  // ('Supreme', 'supreme-bold-italic', 700, 'italic'),
  // ('Supreme', 'supreme-extra-bold', 800, 'normal'),
  // ('Supreme', 'supreme-extra-bold-italic', 800, 'italic')
);

@each $font in $fonts {
  $family: list.nth($font, 1);
  $file: list.nth($font, 2);
  $weight: list.nth($font, 3);
  $style: list.nth($font, 4);

  @font-face {
    font-family: $family;
    src:
      url('../assets/fonts/#{$file}.woff2') format('woff2'),
      url('../assets/fonts/#{$file}.woff') format('woff');
    font-weight: #{$weight};
    font-style: #{$style};
    font-display: swap;
  }
}
