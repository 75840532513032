$color-green-bright: #5df877;
$color-green-dark: #0abb28;
$color-white: #fff;
$color-black: #000;

$line-height: 1.5;
$line-height-headlines: 1;

$font-family-copy: 'Supreme';
$font-family-headlines: 'Optician Sans';
$font-family-fancy: 'Ogg';

$font-weight-copy: 500;
$font-weight-copy-bold: 700;
$font-weight-headlines: 400;
$font-weight-fancy: 400;
